.footer {
  background-color: rgb(15, 15, 15);
  width: 100%;
  position: relative;
  margin-top: 100px;
}

.footer_block-content {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 50px;
  padding: 30px;
}

.footer_block-content_element {
  text-decoration: none;
  cursor: pointer;
  font-family: InterTight-Regular;
  font-size: 22px;
  color: rgb(44, 75, 166);
  transition: color 0.3s ease;
}

.footer_block-content_element:hover {
  color: rgb(255, 255, 255)
}

.footer_block-content_copyrights {
  font-size: 16px;
  font-family: InterTight-Regular;
  text-align: center;
  color: rgb(44, 75, 166);
  padding-bottom: 40px;
}

.links {
  margin: 30px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  width: 200px;
}

.links1 img {
  width: 50px;
}

.links2 img {
  width: 50px;
}

.links3 img {
  margin-left: 5px;
  width: 50px;
}

.links1 img:hover {
  background: radial-gradient(rgb(50, 222, 98), rgba(0, 0, 0, 0) 65%);
}

.links2 img:hover {
  filter: invert(90%) sepia(10%) saturate(4000%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

.links3 img:hover {
  border-radius: 60%;
  background: radial-gradient(rgb(0, 89, 255), rgba(0, 0, 0, 0.2) 90%);

}

.hr_foter {
  width: 200px;
  margin: auto;
  color: rgb(46, 81, 188)
}


@media only screen and (max-width: 600px) {
  .footer_block-content_copyrights {
    font-size: 12px;
  }
}