html.lenis {
    height: auto
}

.lenis.lenis-smooth {
    scroll-behavior: auto
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain
}

.lenis.lenis-stopped {
    overflow: hidden
}

.lenis.lenis-scrolling iframe {
    pointer-events: none
}

.home_page_presentation {
    font-family: InterTight-Regular;
    font-size: 15px;
    width: 150px;
}

.home_page_presentation a {
    text-decoration: none;
}

.home_page_category_inf {
    font-family: InterTight-Regular;
}

.home_page_list_disciplines_item {
    font-family: 'InterTight-Regular';
    font-size: 16px;
    padding-bottom: 7px;
}

.exp_blocks {
    margin: 20px 100px;
    display: grid;

    grid-template-columns: repeat(2, 0fr);
    grid-template-rows: 0fr;
    grid-column-gap: 2px;
    grid-row-gap: 0px;
}

.exp_block_1 {
    color: #7319fa;
    background-image: -webkit-linear-gradient(95deg, #a15bcc, #3c5bf6);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    font-family: InterTight-Regular;
    font-size: 32px;
    width: 200px;
    height: 200px;
    padding: 20px;
    background-color: rgb(57, 57, 57);
}

.exp_block_1_back {
    transition: all 0.3s ease-in-out;
    border-radius: 20px;
    background-color: #393939;

    &.exp_block_1_back:hover {
        background-color: #ffffff;

    }
}



.exp_block_2 {
    color: #7319fa;
    background-image: -webkit-linear-gradient(-45deg, #a15bcc, #868ae1);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    font-family: InterTight-Regular;
    font-size: 32px;
    width: 200px;
    height: 200px;
    padding: 20px;

}

.exp_block_2_back {
    border-radius: 20px;
    background-color: #393939;
    transition: all 0.3s ease-in-out;

    &.exp_block_2_back:hover {
        background-color: #ffffff;

    }
}

.home_page_presentation_a {
    background-color: #393939;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    padding: 18px;
    margin-bottom: 5px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.106);
    font-family: InterTight-Regular;
    font-size: 18px;
    text-decoration: none;
    color: rgb(189, 189, 189);
}

.home_page_presentation_a:hover {
    background-color: #2d2d2d;
}

.home_page_CV {
    background-color: #393939;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    padding: 18px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.106);
    font-family: InterTight-Regular;
    font-size: 18px;
    text-decoration: none;
    color: rgb(56, 112, 233);
}

.home_page_CV:hover {
    background-color: #2d2d2d;
}


.home_page_block {
    user-select: none;
    min-height: 1000px;
    border-radius: 50px;
    margin: 10px auto;
    background-color: rgba(255, 255, 255, 0.111);
    max-width: 800px;
}

@media only screen and (max-width: 600px) {
    .home_page_block {
        background-color: rgba(15, 15, 15, 0);
        max-width: auto;
    }

    .exp_blocks {
        margin: auto 50px;
        display: grid;
        grid-template-columns: repeat(1, 0fr);
        grid-template-rows: 0fr;
        grid-column-gap: 2px;
        grid-row-gap: 2px;
    }
}

.home_page_title {
    font-family: InterTight-Bold;
    font-size: 50px;
    color: rgb(255, 255, 255);

}

.home_page_title_2 {
    color: rgb(184, 184, 184);
    font-family: InterTight-Medium;
    font-size: 2em;
    padding-bottom: 10px;

}

@media only screen and (max-width: 600px) {
    .home_page_title_2 {
        font-size: 1em;
    }
}


.home_page_title_intro {
    padding-top: 30px;
    display: flex;
}


@media only screen and (max-width: 600px) {
    .home_page_title_intro {
        display: block;
    }

}

.home_page_title_name {
    text-align: right;
    margin-right: 60px;
    font-family: InterTight-Regular;
    font-size: 25px;

}


.home_page_title_job {
    text-align: right;
    margin-right: 60px;
    font-family: InterTight-Regular;
    color: rgb(255, 255, 255);
    font-size: 14px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 600px) {
    .home_page_title_name {
        text-align: left;
    }

    .home_page_title_job {
        text-align: left;
    }

}

.home_page_block-info {
    color: rgb(220, 220, 220);
    padding: 50px 50px 50px 50px;
    text-align: left;
}

.home_page_title_desc {
    width: 300px;
    text-indent: 30px;
    font-family: InterTight-Regular;
    font-size: 15px;
    padding-bottom: 30px;
    word-wrap: break-word;
    margin-right: 20px;
}

.home_page_category_title {
    font-size: 25px;
    font-family: InterTight-Medium;
    padding-bottom: 10px;
}

.home_page_category_info {
    font-family: InterTight-Regular;
    font-size: 15px;
    padding-bottom: 40px;

}

.home_page_photo {
    border-radius: 50px;
    width: 85%;
}

.conf_photo_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.conf_photo {
    border-radius: 50px;
    max-width: 90%;
    height: auto;
}

::marker {
    color: rgb(114, 236, 89);
}


.home_page_title_job,
.home_page_title_desc,
.home_page_category_title,
.home_page_category_info {
    opacity: 0;
    animation: fadeIn 0.7s ease-in-out 1 forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}




.home_page_contact_links1 {
    width: 150px;
    margin: auto;
    background-color: #393939;
    transition: all 0.2s ease-in-out;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    padding: 18px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.106);
    font-family: InterTight-Regular;
    font-size: 18px;
    text-decoration: none;
    color: rgb(99, 99, 99);
}

.home_page_contact_links1:hover {
    background-color: #2d2d2d;
}





.home_page_list_disciplines {
    margin: 20px;
}

.uslugi_li {
    top: 200px;
    position: relative;
    font-size: 17px;
    color: #ffffff;
}

.uslugi_li::marker {
    color: rgb(0, 255, 106);
}

.containerrr {
    font-family: InterTight-Regular;
    font-size: 15px;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(500px);
    grid-template-rows: 1fr 2fr;
    grid-template-areas:
        ". ."
        ". ."
        ". .";
}



.work_place_name {
    color: rgb(255, 255, 255);
    width: 170px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.096);
}


.work_info {
    line-height: 1.5;
    padding-bottom: 50px;
}


.work_date {
    width: 170px;
    color: rgb(255, 255, 255);
}

.work_name {
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 600px) {
    .containerrr {
        display: grid;
        grid-template-columns: minmax(300px, 1fr) minmax(500px);
        grid-template-rows: 1fr 0.2fr;
        grid-template-areas:
            "."
            "."
            ".";
    }

    .work_place_name {
        font-size: 20px;
        color: rgb(255, 255, 255);
        width: auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.096);
    }

    .work_date_job {
        width: 150px;

    }

    .work_info {
        width: auto;
        margin-top: 30px;
    }

    .work_date {
        width: 120px;
        margin-bottom: 10px;
    }

}

.container_block {
    border-radius: 20px;
}



.container_couch {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.container_item_couch {
    position: relative;
    color: rgb(87, 124, 255);
    border-radius: 15px;
    font-size: 14px;
    border: 1px solid rgba(200, 200, 200, 0.05);
    padding: 22px;
    background-color: rgba(190, 190, 190, 0.027);
    width: 160px;
    height: 350px;
}


@media only screen and (max-width: 600px) {
    .container_couch {
        display: grid;
        grid-template-rows: 0.5fr;
    }

    .container_item_couch {
        margin-top: 5px;
    }

}

.line_break2 {
    width: auto;
    padding-bottom: 20px;
    height: 0;
    border: none;
    border-top: 1px solid #3333334d;
}

.keyskills_heads {
    background-color: rgba(38, 60, 95, 0.115);
    color: rgb(142, 171, 251);
    font-family: InterTight-Regular;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.096);
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    display: flex;
    margin-bottom: 10px;
}


.keyskills_items {
    line-height: 1.3;
    font-family: InterTight-Regular;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    display: flex;
    margin-bottom: 50px;
}

.keyskills_items_block {
    align-self: flex-start;
}

@media only screen and (max-width: 600px) {
    .keyskills_heads {
        font-size: 12px;
    }

    .keyskills_items {
        font-size: 12px;
    }

}

.container_item_couch img {
    width: 100%;
    padding: 0px;
    display: flex;
    margin: auto;
    position: absolute;
    left: 3px;

    transform: scale(0.9);


}

.spline_scene {
    user-select: none;
    left: -120px;
    bottom: 120px;
    position: absolute;
    transform: scale(0.8);
}