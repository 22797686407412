.header_menu {
    z-index: 1;
    top: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(88, 101, 159, 0.168);
    height: 80px;
    position: fixed;
    display: flex;
    width: 100%;
    transition: top 0.3s ease;
}


.header_link {
    font-family: InterTight-Medium;
    font-size: 20px;
    margin: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    transition: color 0.1s ease, font-size 0.2s ease;

}


.switch_language_button {
    padding: 28px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-family: "InterTight-Regular";
    font-size: 20px;
    text-align: left;
    border: none;
    background-color: rgba(250, 235, 215, 0);
    transition: all 0.1s ease-in-out;
}

.switch_language_button:hover {
    color: rgb(0, 255, 145);
    background-color: rgba(255, 255, 255, 0.145);
}

.icon_flag {
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
    width: 20px;
    top: 2px;
}

.header_link:hover {
    color: rgba(0, 0, 0, 0.091);
}


.header_menu.scrolled {
    top: -100px;
    /* Прокрутите больше или меньше, в зависимости от того, насколько свернут будет header_menu */
}

/* Анимация при прокрутке вверх */
.header_menu.scrolled-up {
    top: 0;
}


.header_link3 {
    font-family: InterTight-Regular;
    font-size: 15px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    transition: color 0.1s ease;
}

.header_first {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.header_flex1 {
    display: grid;
    padding: 20px;
    font-size: 20px;
    text-decoration: none;
    font-family: InterTight-Regular;
    cursor: pointer;
    align-items: center;
    display: flex;
    color: rgb(255, 255, 255);
    border-bottom: 2px solid rgba(172, 255, 47, 0);
}

.header_flex1:hover {
    color: rgb(103, 238, 173);
    border-bottom: 1px solid rgb(110, 240, 169);
    background-color: rgba(0, 0, 0, 0.166);
}


.header_link2 {
    font-family: InterTight-Regular;
    font-size: 13px;
    text-decoration: none;
    color: rgb(170, 170, 170);
    transition: color 0.1s ease;
}

@media (max-width: 960px) {
    .header_link {
        font-size: 14px;
        margin: 8px;
    }

    .header_first {
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .header_link2 {
        position: absolute;
        left: 25px;
        margin-top: 35px;
        font-family: InterTight-Regular;
        font-size: 12px;
    }

    .header_link3 {
        width: 70px;
        font-family: InterTight-Regular;
        font-size: 14px;
        display: flex;
    }


    .switch_language_button {
        line-height: 30px;
        font-size: 20px;
        text-align: center;
        padding: 25px;
        width: 80px;
    }

    .icon_flag {
        top: 5px;
        position: relative;
    }

}