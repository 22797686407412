@font-face {
    font-family: InterTight-Bold;
    src: url('fonts/InterTight-Bold.ttf')
}

@font-face {
    font-family: InterTight-Medium;
    src: url('fonts/InterTight-Medium.ttf')
}

@font-face {
    font-family: InterTight-Regular;
    src: url('fonts/InterTight-Regular.ttf')
}