.articles_page_title_name {
    font-family: InterTight-Regular;
    font-size: 18px;
    color: rgb(150, 150, 300);
}

@media only screen and (max-width: 600px) {
    .articles_page_title_name {
        font-size: 20px;

    }
}

.articles_page_title_job {
    margin-top: 5px;
    font-family: InterTight-Regular;
    font-size: 15px;
}

@media only screen and (max-width: 600px) {
    .home_page_block {
        border-radius: 0px;
        max-width: auto;
    }
}



@media only screen and (max-width: 600px) {
    .home_page_title_2 {
        font-size: 1em;
    }
}


.home_page_title_intro {
    padding-top: 30px;
    display: flex;
}


@media only screen and (max-width: 600px) {
    .home_page_title_intro {
        display: block;
    }

}




@media only screen and (max-width: 600px) {
    .home_page_title_name {
        text-align: left;
    }

    .home_page_title_job {
        text-align: left;
    }

}

.home_page_block-info {

    color: rgb(220, 220, 220);
    padding: 50px 50px 50px 50px;
    text-align: left;
}

.home_page_title_desc {
    width: 300px;
    text-indent: 30px;
    font-family: InterTight-Regular;
    font-size: 15px;
    padding-bottom: 30px;
    word-wrap: break-word;
    margin-right: 20px;
}

.home_page_category_title {
    font-size: 25px;
    font-family: InterTight-Medium;

}

.articles_page_category_info {
    font-family: InterTight-Regular;
    font-size: 15px;

}



.home_page_title_job,
.home_page_title_desc,
.home_page_category_title,
.home_page_category_info {
    opacity: 0;
    animation: fadeIn 0.7s ease-in-out 1 forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}




.containerr {
    font-family: InterTight-Regular;
    font-size: 13px;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(500px);
    grid-template-rows: 1fr 2fr;
    grid-template-areas:
        ". ."
        ". ."
        ". .";
}

.line_break2 {
    width: auto;
    padding-bottom: 10px;
    height: 0;
    border: none;
    border-top: 1px solid #3333334d;
}

.photo_article {
    border: 1px solid rgba(144, 144, 144, 0.267);
    width: 100%;
}