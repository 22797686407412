* {
  margin: 0;
  padding: 0;
}


body {
  /* background-image: url('../public/ooorganize.svg'); */
  margin-top: 105px;
  height: 100%;
  background-size: 100%;
  background-color: rgb(47, 47, 47);
  /* background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgb(16, 28, 70) 0%, rgb(106, 113, 195) 100%); */
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

}